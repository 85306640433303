<template>
  <div class="preview" @click="handleClose">
    <div class="overlay" @touchmove="onTouchMoveOverlay($event)"></div>
    <img
      id="mobile-preview-img"
      class="picture"
      :src="src"
      @touchstart="onTouchStart($event)"
      @touchmove="onTouchMove($event)"
    />
  </div>
</template>

<script>
  export default {
    name: 'Preview',
    components: {},
    props: {
      src: {
        type: String,
        default: '',
      },
      onDestroy: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        x: 0,
        y: 0,
        id: 0,
        scale: 1,
        scrollX: 0,
        scrollY: 0,
        scrollHorizontal: 0,
        scrollVertical: 0,
        isScroll: false, // 是否能够滑动图片
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      document.body.style.overflow = 'hidden';
      this.$nextTick(() => {
        const ret = document.querySelector('#mobile-preview-img');
        this.scrollVertical = +/^\d+/gi.exec(getComputedStyle(ret).top)[0];
      });
    },
    destroyed() {
      document.body.style.overflow = 'auto';
    },
    methods: {
      handleClose() {
        this.onDestroy();
      },
      onTouchStart(e) {
        if (e.targetTouches.length === 1) {
          this.scrollX = e.targetTouches[0].clientX;
          this.scrollY = e.targetTouches[0].clientY;
          this.isScroll = true;
        } else if (e.targetTouches.length === 2) {
          this.isScroll = false;
          this.id =
            e.targetTouches[0].clientY > e.targetTouches[1].clientY
              ? e.targetTouches[0].identifier
              : e.targetTouches[1].identifier;
          for (let touch of e.targetTouches) {
            if (this.id === touch.identifier) {
              this.x = touch.clientX;
              this.y = touch.clientY;
            }
          }
        }
      },
      onTouchMove(e) {
        e.preventDefault();
        if (e.targetTouches.length === 1 && this.isScroll) {
          const ret = document.querySelector('#mobile-preview-img');
          let pointer = e.targetTouches[0];
          const h = pointer.clientX - this.scrollX;
          const v = pointer.clientY - this.scrollY;
          this.scrollHorizontal = this.scrollHorizontal + h;
          this.scrollVertical = this.scrollVertical + v;
          this.scrollX = pointer.clientX;
          this.scrollY = pointer.clientY;
          ret.style.left = `${this.scrollHorizontal}px`;
          ret.style.top = `${this.scrollVertical}px`;
        } else if (e.targetTouches.length === 2) {
          this.isScroll = false;
          let pointer = null;
          for (let touch of e.targetTouches) {
            if (this.id === touch.identifier) {
              pointer = touch;
            }
          }
          const ret = document.querySelector('#mobile-preview-img');
          const cx = pointer.clientX;
          const cy = pointer.clientY;
          const deltaX = cx - this.x;
          const deltaY = cy - this.y;
          let direction = 'up'; // up-放大 down-缩小
          if (deltaY <= 0) {
            direction = 'down';
          } else {
            direction = 'up';
          }
          const maxDelta = Math.max(Math.abs(deltaX), Math.abs(deltaY));
          const s = (maxDelta / document.body.clientHeight) * 5;
          let scale = direction === 'up' ? this.scale + s : this.scale - s;
          scale < 0.5 && (scale = 0.5);
          scale > 2 && (scale = 2);
          //   console.log(direction, '  ', maxDelta, '  ', this.scale, '  ', scale, '  ', cy, '  ', this.y);
          this.scale = scale;
          this.x = cx;
          this.y = cy;
          ret.style.transform = `translateY(-50%) scale(${scale})`;
        }
      },
      onTouchMoveOverlay(e) {
        e.preventDefault();
      },
    },
  };
</script>

<style lang="less" scoped>
  .preview {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    animation: gradient 0.5s ease;
    @keyframes gradient {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .overlay {
      background: rgba(0, 0, 0, 0.5);
      height: 100%;
      width: 100%;
    }
    .picture {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      transition: transform 0.1s ease;
      transform-origin: center center;
    }
  }
</style>
