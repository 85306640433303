import Vue from 'vue';
import PreviewConstructor from './Preview.vue';

class Preview {
  vm; // vnode

  show(opt = {}) {
    this.initRender();
    const { src = '' } = opt;
    const Constructor = Vue.extend(PreviewConstructor);
    this.vm = new Constructor({});
    this.vm._props.src = src;
    this.vm.$mount();

    // 防止内存溢出
    this.vm._props.onDestroy = () => {
      setTimeout(() => {
        this.vm.$destroy();
        this.vm.$el.remove();
        this.vm = null;
      }, 500);
    };

    document.body.appendChild(this.vm.$el);
  }

  initRender() {
    if (this.vm) {
      this.vm.$destroy();
      this.vm = null;
    }
  }
}

export default Preview;
