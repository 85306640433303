<template>
  <div class="container">
    <!-- 添加到桌面 -->
    <div v-if="type === 'add_desktop'">
      <div class="title">如何将小组件添加到桌面</div>
      <div class="second-title">1. 打开IUV，选择你喜欢的小组件，修改好你想要的颜色、背景等，点击【保存】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/add_desktop_1.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">2. 返回到手机桌面，长按屏幕，点击左上角【+】号，选择IUV。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/add_desktop_2.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">3. 选择要添加的小组件大小，点击【添加小组件】，再点击【完成】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/add_desktop_3.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">4. 长按小组件，选择【编辑小组件】，在【当前组件类型】一栏点击【选取】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/add_desktop_4.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">5. 选择你想要的小组件，退回到桌面即可。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/add_desktop_5.png"
        @click="handlePreviewImg($event)"
      />
    </div>
    <!-- 添加到锁屏 -->
    <div v-if="type === 'add_lock'">
      <div class="title">如何将小组件添加到锁屏界面</div>
      <div class="second-title">1. 打开IUV，在【锁屏精选】栏选择您要添加的小组件，点击【保存】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/add_lock_1.jpeg"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">2. 退回到锁屏界面，长按屏幕，待屏幕进入锁屏设置页，点击【自定】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/add_lock_2.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">3. 选择【锁定屏幕】，点击【添加小组件】</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/add_lock_3.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">4. 选择IUV，点击要添加的小组件类型</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/add_lock_4.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">5. 待添加到小组件栏后点击小组件，选择你要展示的组件类型。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/add_lock_5.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">6. 关掉下方弹窗，点击【完成】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/add_lock_6.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">7. 选择当前壁纸即可完成设置。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/add_lock_7.png"
        @click="handlePreviewImg($event)"
      />
    </div>
    <!-- 设置主题 -->
    <div v-if="type === 'set_theme'">
      <div class="title">如何设置主题</div>
      <h1>第一步：设置壁纸</h1>
      <div class="second-title">1. 选择你喜欢的主题，点击【立即使用】</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_theme_1.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">2. 点击保存壁纸，然后再打开手机相册，选择保存好的壁纸，点击左下角选项。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_theme_2.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">3. 选择【用作墙纸】，编辑锁屏后，点击【完成】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_theme_3.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">4. 选择自定义主屏幕，关闭【模糊】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_theme_4.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">5. 点击【完成】，手机壁纸就设计好了。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_theme_5.png"
        @click="handlePreviewImg($event)"
      />
      <h1>第二步：添加桌面组件</h1>
      <div class="second-title">
        1. 选择你喜欢的主题，点击【保存组件】。然后退回到手机桌面，长按屏幕进入编辑模式，点击右上角加号。
      </div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_theme_6.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">2. 选择小组件IUV，选择你想要的组件类型，点击【添加小组件】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_theme_7.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">3. 待小组件添加到屏幕之后点击【完成】，长按小组件，点击【编辑小组件】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_theme_8.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">4. 点击【当前组件类型】一栏的【选取】，选择你要添加的小组件。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_theme_9.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">5. 点击空白区域退回到主屏幕即可完成小组件添加设置。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_theme_10.png"
        @click="handlePreviewImg($event)"
      />
      <h1>第三步：修改桌面图标</h1>
      <div class="second-title">1. 选择喜欢的主题后，在图标页点击【一键安装】，再在跳转的页面点击【下载】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_theme_11.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">2. 在弹窗里选择【允许】，待下载好之后打开手机设置。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_theme_12.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">3. 选择【通用】，再选择【VPN与设备管理】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_theme_13.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">4. 选择已下载的描述文件，点击【安装】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_theme_14.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">5. 输入手机密码，点击【安装】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_theme_15.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">6. 待安装好之后点击【完成】。主题就设置完毕。返回到手机桌面即可查看当前设置主题。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_theme_16.png"
        @click="handlePreviewImg($event)"
      />
    </div>
    <!-- 添加主题组件到桌面 -->
    <div v-if="type === 'add_theme_desktop'">
      <div class="title">如何添加主题小组件到桌面</div>
      <div class="second-title">
        1. 选择你喜欢的主题，点击【保存组件】。然后退回到手机桌面，长按屏幕进入编辑模式，点击右上角加号。
      </div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/add_theme_desktop_1.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">2. 选择小组件IUV，选择你想要的组件类型，点击【添加小组件】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/add_theme_desktop_2.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">3. 待小组件添加到屏幕之后点击【完成】，长按小组件，点击【编辑小组件】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/add_theme_desktop_3.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">4. 点击【当前组件类型】一栏的【选取】，选择你要添加的小组件。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/add_theme_desktop_4.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">5. 点击空白区域退回到主屏幕即可完成小组件添加设置。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/add_theme_desktop_5.png"
        @click="handlePreviewImg($event)"
      />
    </div>
    <!-- 安装主题图标 -->
    <div v-if="type === 'install_theme_icon'">
      <div class="title">如何安装图标</div>
      <div class="second-title">1. 选择喜欢的主题后，在图标页点击【一键安装】，再在跳转的页面点击【下载】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/install_theme_icon_1.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">2. 在弹窗里选择【允许】，待下载好之后打开手机设置。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/install_theme_icon_2.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">3. 选择【通用】，再选择【VPN与设备管理】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/install_theme_icon_3.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">4. 选择已下载的描述文件，点击【安装】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/install_theme_icon_4.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">5. 输入手机密码，点击【安装】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/install_theme_icon_5.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">6. 待安装好之后点击【完成】。主题就设置完毕。返回到手机桌面即可查看当前设置主题。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/install_theme_icon_6.png"
        @click="handlePreviewImg($event)"
      />
    </div>
    <!-- 设置壁纸 -->
    <div v-if="type === 'set_wallpaper'">
      <div class="title">如何设置壁纸</div>
      <div class="second-title">1. 选择你喜欢的主题，点击【立即使用】</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_wallpaper_1.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">2. 点击保存壁纸，然后再打开手机相册，选择保存好的壁纸，点击左下角选项。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_wallpaper_2.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">3. 选择【用作墙纸】，编辑锁屏后，点击【完成】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_wallpaper_3.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">4. 选择自定义主屏幕，关闭【模糊】。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_wallpaper_4.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">5. 点击【完成】，手机壁纸就设计好了。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_wallpaper_5.png"
        @click="handlePreviewImg($event)"
      />
    </div>
    <!-- 小组件透明背景 -->
    <div v-if="type === 'set_alpha'">
      <div class="title">小组件透明背景设置教程</div>
      <div class="second-title">
        1.
        首先准备一个没有图标的桌面，然后分别在深色模式和浅色模式下截图一张（打开：手机设置—显示与亮度，即可设置手机颜色模式）。
      </div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_alpha_1.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">
        2. 打开IUV，选择一个可以设置背景的小组件，点击【透明】，分别添加步骤1里的浅色截图和深色截图。
      </div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_alpha_2.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">
        3.
        待添加好之后，返回到桌面，找到已添加的IUV小组件，长按该小组件，点击【编辑小组件】，选择【透明背景】栏的【选取】。
      </div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_alpha_3.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">4. 选择小组件当前摆放位置。退回到桌面即可完成透明背景设置。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/set_alpha_4.png"
        @click="handlePreviewImg($event)"
      />
    </div>
    <!-- 开通会员 -->
    <div v-if="type === 'join_vip'">
      <div class="title">如何开通会员</div>
      <div class="second-title">1. 点击会员图标，进入会员开通页面，选择你想要开通的套餐。</div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/join_vip_1.png"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">
        2. 选好套餐之后，点击【立即成为VIP】，再在弹窗中选择【订阅】即可 (首次购买需要输入Apple
        Store的账号密码作为验证）。
      </div>
      <img
        class="intro-img"
        src="../../../assets/images/widgets-tutorial/join_vip_2.png"
        @click="handlePreviewImg($event)"
      />
    </div>
  </div>
</template>

<script>
  import Preview from '@/components/PreviewImg';
  export default {
    components: {},
    props: {
      type: {
        type: String,
      },
    },
    data() {
      return {
        previewInst: null,
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.previewInst = new Preview();
    },
    methods: {
      handlePreviewImg(e) {
        this.previewInst.show({ src: e.target.src });
      },
    },
  };
</script>

<style lang="less" scoped>
  .container {
    font-family: 'PingFang SC', tahoma, arial, 'helvetica neue', 'hiragino sans gb', 'microsoft yahei', sans-serif;
    padding: 0.2rem 0.4rem;
    img {
      margin: 0.3rem auto;
    }
    h1 {
      font-size: 0.28rem;
      margin-bottom: 0.3rem;
      color: #2c2c2c;
      font-weight: 500;
      line-height: 0.52rem;
    }
    .title {
      font-size: 0.42rem;
      color: #2c2c2c;
      line-height: 0.48rem;
      padding: 0.35rem 0;
      border-bottom: 1px solid #ced5df;
      margin-bottom: 0.3rem;
      font-weight: bold;
    }
    .second-title {
      font-size: 0.3rem;
      word-break: break-word;
      line-height: 0.52rem;
      color: #2c2c2c;
    }
    .intro-img {
      max-width: 100%;
      width: auto;
      height: auto;
      border-radius: 4px;
    }
  }
</style>
